body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  background-color: #d9d9d9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.App-header {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.App-title {
  font-size: 3rem;
  margin: 0;
  text-align: center;
  color: #262221;
}

.App-statement-title {
  font-size: 2rem;
  margin: 20px 0;
  text-align: center;
  color: #736356;
}

.App-statement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.App-statement {
  font-size: 1.2rem;
  max-width: 800px;
  padding: 0 20px;
  color: #736356;
  text-align: center;
}

.App-statement-question {
  font-size: 2rem;
  margin: 20px 0;
  text-align: center;
  color: #262221;
}

.App-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows boxes to wrap on smaller screens */
}

.box {
  width: 300px;
  height: 600px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; /* Prevents shrinking of the box */
}

.box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.box:hover .box-inner {
  transform: rotateY(180deg);
}

.box-front, .box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.box-front {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-front img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images fill the box without distortion */
}

.box-back {
  background-color: #000000;
  color: #bfb4aa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.box-back h2 {
  margin: 0;
  font-size: 1.5rem;
}

.box-back p {
  font-size: 1rem;
  margin-top: 10px;
}


.video-container {
  width: 100%;
  max-width: 1050px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container video {
  width: 100%;
  height: auto;
}

.info-sections {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto multiple lines */
  justify-content: space-between;
  width: 100%;
  max-width: 2200px;
  margin-top: 40px;
  font-size: 17px;
  color: #262221;
}

.info-sections section {
  flex: 1 1 30%; /* Ensures sections take up 30% of the container's width */
  border: 5px solid #bfb4aa;
  padding: 5px; /* Optional: Adds space inside the border */
  margin: 10px; /* Adds space outside the border */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.info-sections ul {
  list-style-type: none;
  padding: 0; /* Optional: Removes the default padding */
  margin: 0;  /* Optional: Removes the default margin */
  text-align: center;
  font-size: 17px;
}

.info-sections li {
  margin-bottom: 10px; /* Adds space between each list item */
}

/* Optional: Removes the margin from the last list item */
.info-sections li:last-child {
  margin-bottom: 0;
}

section {
  margin: 20px 0;
  text-align: center;
}


.team {
  text-align: center;
  margin: 20px 0;
  color: #262221;
}

.team h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #262221;
}

.team-members {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.team-member {
  text-align: center;
}

.team-member img {
  width: 311px;
  height: 415px;
  margin-bottom: 10px;
}

.team-member h3 {
  margin: 10px 0 5px;
  font-size: 1.2rem;
  color: #262221;
}

.team-member p {
  margin: 0;
  font-size: 1rem;
  color: #262221;
}

.contact {
  font-size: 1.2rem;
}

/* Media queries for smaller screens */
@media only screen and (max-width: 768px) {
  .App-statement-title {
    font-size: 1.5rem;
  }

  .App-statement {
    font-size: 1rem;
    max-width: 500px;
  }

  .info-sections {
    flex-direction: column; /* Stacks sections vertically */
    align-items: center; /* Centers the sections */
  }

  .info-sections section {
    width: 90%; /* Adjust width to fit within the mobile view */
    margin: 10px 0; /* Adjust margin for spacing */
  }

  .App-content {
    flex-direction: column;
  }

  .box {
    width: 100%;
    height: auto; /* Let height adjust automatically based on content */
    margin: 10px 0; /* Adjust margin for spacing */
  }

  .box-inner {
    height: auto; /* Let the height of the inner box adjust */
  }
  
  .box-front img {
    width: 100%;
    height: auto; /* Let height adjust automatically to maintain aspect ratio */
  }

  .team-members {
    flex-direction: column; /* Stack team members vertically */
    align-items: center; /* Center team members horizontally */
  }
  
  .team-member {
    margin-bottom: 20px; /* Add spacing between team members */
  }
  
  .team-member img {
    width: 100%; /* Make images fill the container width */
    height: auto; /* Let height adjust automatically to maintain aspect ratio */
    max-width: 311px; /* Limit maximum width to original size */
  }


 
}
